let isInAppView =
  document.location.search.indexOf('inAppView') !== -1 || document.location.search.indexOf('X-TUIC-Webview') !== -1;

if (document.location.search.indexOf('inAppView=0') !== -1) {
  localStorage.removeItem('inAppView');
  window.history.replaceState({}, '', window.location.href.replace('inAppView=0', ''));
  isInAppView = false;
} else if (isInAppView) {
  localStorage.setItem('inAppView', '1');
}

const isInAppViewStorage = localStorage.getItem('inAppView') === '1';
module.exports = !!isInAppView || !!isInAppViewStorage || false;
